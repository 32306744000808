.custom-date-picker-wrapper {
	position: relative;
	width: 100%;
}

.custom-date-picker-wrapper > button {
	background-color: transparent;
	border: 0;
	position: absolute;
	right: 1rem;
	top: 50%;
	transform: translateY(-50%);
	padding: 0;
	display: flex;
}

.custom-date-picker-wrapper > button svg {
	width: 15px;
	height: 15px;
}

.react-datepicker__input-container input {
	display: block;
	width: 100%;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	appearance: none;
	border-radius: 0.25rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.react-datepicker__input-container input:focus {
	color: #212529;
	background-color: #fff;
	border-color: #86b7fe;
	outline: 0;
	box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.react-datepicker__input-container input::placeholder {
	color: #212529;
}

.react-datepicker__navigation {
	top: 8px;
}

.react-datepicker-wrapper {
	width: 100%;
}

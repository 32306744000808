.question-modal-container {
    text-align: center;
}

.icon-container {
    font-size: 50px;
}

.backdrop-blur {
    backdrop-filter: blur(2px) brightness(60%);
}

.modal-backdrop {
    background-color: unset !important;
    opacity: unset !important;
}
.subscibe-modal-container {
    text-align: left;
}

.subscribe-link {
    width: 100%;
    display:flex;
    flex-direction: row;
    justify-content: center;
}

.backdrop-blur {
    backdrop-filter: blur(2px) brightness(60%);
}

.modal-backdrop {
    background-color: unset !important;
    opacity: unset !important;
}
@font-face {
    font-family: 'dejavu';
    src: local('MyFont'), url('../assets/fonts/DejaVuSans.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'courier';
    src: local('MyFont'), url('../assets/fonts/CourierPrime-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Hurricane";
    src: local('MyFont'), url('../assets/fonts/Hurricane-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Mrs Saint Delafield";
    src: local('MyFont'), url('../assets/fonts/MrsSaintDelafield-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Alex Brush";
    src: local('MyFont'), url('../assets/fonts/AlexBrush-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Dawning of a New Day";
    src: local('MyFont'), url('../assets/fonts/DawningofaNewDay-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Homemade Apple";
    src: local('MyFont'), url('../assets/fonts/HomemadeApple-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Nanum Brush Script";
    src: local('MyFont'), url('../assets/fonts/NanumBrushScript-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Rock salt";
    src: local('MyFont'), url('../assets/fonts/RockSalt-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Zeyada";
    src: local('MyFont'), url('../assets/fonts/Zeyada-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

html,
body,
#root {
    height: 100%;
    overflow: hidden;
    font-size: 15px;
    scroll-behavior: smooth;
}

.spinner {
    animation: spin infinite 1s linear;
}

.note p{
	margin: 0;
}


@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* Main app styles */

.main-app-container {
    width: 100%;
    height: 100%;
}

.left-pane {
    height: 100%;
    background: transparent;
    padding: 15px 7px 15px 15px;
}

.left-pane-wrapper {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: white;
}

main.main-body {
    width: 100%;
    height: calc(100% - 45px);
    display: flex;
    background: #f1f1f1;
}

.right-pane {
    position: relative;
    height: 100%;
    background: transparent;
    padding: 15px 15px 15px 7px;
}

.right-pane-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: white;
    overflow-y: scroll;
}



.note {
    display: flex;
    flex-direction: column;
    height: 100%;
}

/* Note designer styles */

.designer-container {
    width: 100%;
    height: 100%;
}

.creator-body {
    width: 100%;
    height: calc(100% - 45px);
    display: flex;
}

.creator-body-panel {
    width: 100%;
    height: 100%;
    background: white;
}

/* Note selector styles */

.tab-pane {
    height: 100%;
}

.heading-pane {
    height: 100%;
    overflow: auto;
    padding: 0 5px;
}

.tab-content {
    padding: 20px;
    height: calc(100% - 65px);
}

.dashboard-body {
    display: flex;
    min-height: calc(100vh - 56px);
}

.dashboard-left-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    width: 225px !important;
    background-color: #212529;
    min-height: calc(100vh - 56px);
    transition: 0.2s all;
}

.dashboard-left-nav[data-is-collapsed='true'] {
    width: 100px;
}

.dashboard-left-nav ul {
    list-style: none;
    padding: 0;
}

.dashboard-left-nav ul li {
    color: white;
    font-size: 16px;
    display: flex;
    /* opacity: ${props => (props.isSelected ? 1 : 0.5)}; */
    cursor: pointer;
}

.dashboard-left-nav ul li:hover {
    opacity: 1;
}

.dashboard-left-nav ul li a {
    color: white;
    padding: 12px 15px;
    text-decoration: none;
    width: 100%;
    display: flex;
    align-items: center;
}
.dashboard-left-nav[data-is-collapsed='true'] ul li {
    font-size: 10px;
}

.dashboard-left-nav[data-is-collapsed='true'] ul li a {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    padding: 10px 12px;
}

.dashboard-left-nav .icon-wrapper {
    margin-right: 10px;
    font-size: 22px;
}

.dashboard-left-nav[data-is-collapsed='true'] .icon-wrapper {
    margin-right: 0;
}

.dashboard-left-nav .note-button {
    border: none;
    margin: 15px 20px;
    padding: 10px 0px;
    border-radius: 5px;
    background: #28a745;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    width: 180px;
}

.dashboard-left-nav[data-is-collapsed='true'] .note-button {
    margin-right: 0px;
    margin: 20px 0;
    height: 40px;
    width: 40px;
    border-radius: 100%;
}

.heading-container {
    height: 100%;
    width: 100%;
    padding: 0 10px;
    overflow: auto;
}
.heading-container .button-container {
    padding: 20px 0;
    text-align: center;
    display: flex;
    justify-content: center;
}

div.input-container {
    margin-bottom: 15px;
    width: 100%;
}

.note-content-header {
    height: 65px;
    border-bottom: 1px solid #dee2e6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.note-content-body {
    padding: 10px;
}

.note-content-header-left-btn-group {
    display: inline-flex;
    gap: 5px;
}

.global-preference-terminology-actions {
    display: flex;
    gap: 16px;
    align-items: center;
}

.global-preference-terminology-actions > div {
    flex-basis: 50%;
    flex-grow: 1;
}

.global-preference-terminology[disabled] [role='group'],
.global-preference-terminology[disabled] h6,
.global-preference-terminology[disabled] p {
    opacity: 0.5;
    cursor: not-allowed;
}

#finalNote .intro-comments:empty {
    display: none;
}

#finalNote .image-caption:empty {
    display: none;
}

.sigpad {
    width: 100%;
    height: 100%;
}

.sigImage {
    background-size: 200px 50px;
    width: 200px;
    height: auto;
    background-color: white;
}

.signature-canvas {
    border: 1px solid lightgray;
}

.no-access-info {
    text-align: center;
    position: fixed;
    inset: 50% 0px 0px 50%;
    transform: translate(-50%, -50%);
}

.backdrop-blur {
    backdrop-filter: blur(2px) brightness(60%);
}

.filepond--credits {
    display: none;
}

.__markerjs2_ {
    top: unset !important;
    left: unset !important;
}

.backdrop-blur {
    backdrop-filter: blur(2px) brightness(60%);
}

.pdf-content .image-element {
    page-break-inside: avoid;
}

@media print {
    body {
        overflow: hidden;
        height: unset;

        .navbar {
            display: none;
        }
        .left-pane {
            display: none;
        }
        .right-pane {
            width: unset;
            overflow: hidden;
        }
        .note-content-header {
            display: none;
        }
        .signature-controls {
            display: none;
        }
        .right-pane-wrapper {
            overflow: unset
        }
		.edit-output {
			display: none;
		}
		.intro-comments:empty {
			display: none;
		}
		.image-caption:empty {
			display: none;
		}
    }
  }

.react-chatbot-kit-chat-container {
    width: 350px !important;
    border-radius: 10px !important;
    border: 1px solid lightgray;
}

.react-chatbot-kit-chat-bot-message, .react-chatbot-kit-chat-btn-send {
    background-color:#FF7400 !important;
    color: white !important;
}

.react-chatbot-kit-chat-bot-message-arrow {
    border-right-color: #FF7400 !important;
}
.react-chatbot-kit-chat-btn-send-icon {
    fill: white;
}


.react-chatbot-kit-chat-bot-message, .react-chatbot-kit-user-chat-message {
    width: 80% !important;
    color: black;
    font-size: 1rem;
}

.react-chatbot-kit-user-chat-message {
margin-right: auto !important;
font-size: 1rem;
}

.react-chatbot-kit-chat-inner-container {
    border-radius: 10px !important;
}

.react-chatbot-kit-user-avatar-container {
    background-color: #0e386a !important;
}

.react-chatbot-kit-chat-input:focus-visible  {
    outline: #0e386a  auto 1px;
}

.animated-text-component {
    color: white;
    font-family: 'Oswald', sans-serif;
    font-size: 4em;
    line-height: 1;
    margin: 0;
    padding: 0;
    margin-left: 1rem;
    -webkit-animation: anim 3s linear infinite;
    animation: anim 3s linear infinite;
  }

  .reactour__popover {
    border-radius: 7px;
    padding-top: 35px !important;
    border: 1px solid #3742fa;
  }

  .reactour__mask {
    opacity: 0.4 !important;
  }

  .ql-align-center {
    text-align: center;
  }

  .ql-align-right {
    text-align: right;
  }

  .ql-align-justify {
    text-align: justify;
  }


  @-webkit-keyframes anim {
    0%{text-shadow:-6px 4px 0px red;}
    10% {text-shadow:4px -6px 0px green;}
    20% {text-shadow:-9px 4px 0px blue;}
    30% {text-shadow:4px -6px 0px yellow;}
    40% {text-shadow:-8px 4px 0px orange;}
    60% {text-shadow:-6px 4px 0px brown;}
    70% {text-shadow:4px 7px 0px pink;}
    80% {text-shadow:-9px -4px 0px lime;}
    90% {text-shadow:4px -6px 0px cyan;}
    100% {text-shadow:-9px 4px 0px teal;}
}

@keyframes anim {
    0%{text-shadow:-6px 4px 0px red;}
    10% {text-shadow:4px -6px 0px green;}
    20% {text-shadow:-9px 4px 0px blue;}
    30% {text-shadow:4px -6px 0px yellow;}
    40% {text-shadow:-8px 4px 0px orange;}
    50% {text-shadow:4px 5px 0px purple;}
    60% {text-shadow:-6px 4px 0px brown;}
    70% {text-shadow:4px 7px 0px pink;}
    80% {text-shadow:-9px -4px 0px lime;}
    90% {text-shadow:4px -6px 0px cyan;}
    100% {text-shadow:-9px 4px 0px teal;}
}

.disabled-tippy {
  cursor: not-allowed !important;
  background-color: #eeeeee;
}
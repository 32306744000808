.tippy-box[data-theme~=tippy-nd]{
    background-color:#fff;
    background-clip:padding-box;
    border:2px solid #0e386a;
    color:#333;
    box-shadow:0 4px 14px -2px #0e386a
}

.tippy-box[data-theme~=tippy-nd]>

.tippy-backdrop{background-color:#fff}

.tippy-box[data-theme~=tippy-nd]>

.tippy-arrow:after,

.tippy-box[data-theme~=tippy-nd]>

.tippy-svg-arrow:after{
    content:"";
    position:absolute;
    z-index:-1
}

.tippy-box[data-theme~=tippy-nd]>

.tippy-arrow:after{
    border-color:transparent;
    border-style:solid
}

.tippy-box[data-theme~=tippy-nd][data-placement^=top]>

.tippy-arrow:before{border-top-color:#0e386a}

.tippy-box[data-theme~=tippy-nd][data-placement^=top]>



.tippy-box[data-theme~=tippy-nd][data-placement^=bottom]>

.tippy-arrow:before{
    border-bottom-color:#0e386a;
    bottom:16px
}

.tippy-box[data-theme~=tippy-nd][data-placement^=bottom]>

.tippy-arrow:after{
    border-bottom-color:#0e386a;
    bottom:17px;left:2px
}


.tippy-svg-arrow>svg{bottom:16px}


.tippy-svg-arrow:after{bottom:17px}



.tippy-arrow:before{border-left-color:#0e386a}


.tippy-box[data-theme~=tippy-nd][data-placement^=left]>

.tippy-arrow:after{
    border-left-color:#0e386a;
    border-width:8px 0 8px 8px;
    left:17px;top:2px
}



.tippy-svg-arrow>svg{left:11px}



.tippy-svg-arrow:after{left:12px}

.tippy-box[data-theme~=tippy-nd][data-placement^=right]>

.tippy-arrow:before{border-right-color:#fff;right:16px}

.tippy-box[data-theme~=tippy-nd][data-placement^=right]>

.tippy-arrow:after{
    border-width:8px 8px 10px;
    right:17px;
    border-right-color:#0e386a
}


.tippy-svg-arrow>svg{right:11px}


.tippy-svg-arrow:after{right:12px}

.tippy-box[data-theme~=tippy-nd]>

.tippy-svg-arrow{fill:#fff}

.tippy-box[data-theme~=tippy-nd]>.tippy-svg-arrow:after{background-image:url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMCA2czEuNzk2LS4wMTMgNC42Ny0zLjYxNUM1Ljg1MS45IDYuOTMuMDA2IDggMGMxLjA3LS4wMDYgMi4xNDguODg3IDMuMzQzIDIuMzg1QzE0LjIzMyA2LjAwNSAxNiA2IDE2IDZIMHoiIGZpbGw9InJnYmEoMCwgOCwgMTYsIDAuMikiLz48L3N2Zz4=);background-size:16px 6px;width:16px;height:6px}
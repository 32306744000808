.container {
    height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.custom-backdrop {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
}

@import 'styles/colors';

.choice-format {
    display: flex;
    align-items: left;
  
    .input-field {
      width: 100%;
      margin-right: 1rem;
    }
  
    button {
      background-color: unset;
      border: unset;
      font-size: 1rem;
      color: $navy;
      text-decoration: none;
      cursor: pointer;
  
      &:hover {
        color: $green;
        text-decoration: underline;
      }
    }

    .choice-format-fonts {
        align-items: center;
    }
  
    .active {
      color: $green;
      border-bottom: 2px solid $green;
    }
  }

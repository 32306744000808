.nav-menu-navlinks{
    @media only screen and (min-width: 992px) {
        display: none;
      } 
}

.navlinks-title {
  background-color: #e9ecef;
  text-align: center;
  margin-top: -0.5em;
}